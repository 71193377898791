@font-face {
  font-family: "Roboto";
  src: url("./_assets/fonts/Roboto/Roboto-Regular.ttf");
}


/* Utility */
.cursor-pointer:hover {
  cursor: pointer !important;
}

.linear-gradient {
  background-image: linear-gradient(180deg, RGB(252, 252, 252) 0%, RGB(248, 248, 248) 100%);
}

.letter__spacing {
  letter-spacing: .1rem
}


/* Welcome Section */
.welcome__slide {
  height: 480px;
  background-image: linear-gradient(161.12451094427942deg, RGB(11, 136, 238) 0%,RGB(41, 194, 219) 81%, RGB(41, 194, 219) 87%,RGB(41, 194, 219) 89%,RGB(42, 195, 218) 100%);
}

.bubble__background {
  height: 480px;
  max-width: 1500px;
  background-image: url("./_assets/Home/Bubbles@3x.png");
  background-size: 550px; 
  background-position: left center;
  background-repeat: no-repeat;
}

.welcome__to {
  color: #FFFFFF;
  opacity: .7;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 900;
  letter-spacing: .38rem;
}

.welcome__axs {
  color: #FFFFFF;
  font-size: 46px;
  line-height: 96px;
  font-family: Roboto;
}

.welcome__details {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 24px;
  font-family: Roboto;
}

.welcome__button {
  /* Button */
  width: 240px;
  height: 80px;
  display: inline-block;
  box-shadow: 0px 2px 200px rgba(0, 0, 0, 0.103);
  border-radius: 100px;
  background-color: RGB(255, 255, 255, .30);
  background-repeat: no-repeat;
  background-position: center;

  /* Button Text */
  color: RGBA(255, 255, 255);
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  padding-top: 1.4em;
  transition: width .1s, background-color .2s, font-size .1s;
}

.welcome__button:hover {
  width: calc(240px + 2vw);
  cursor: pointer;
  background-color: RGB(56, 203, 137) !important;
}

.welcome__buttons {
  transition: margin-top .2s, text-align .2s;
}


@media screen and (min-width: 1500px) {
  .welcome__button:hover {
      width:  255px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .welcome__button {
      width: 240px;
      height: 80px;
      font-size: 20px;
  }

  .welcome__button:hover {
      width: 240px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .welcome__button {
      width: 191px;
      height: 78px;
      font-size: 18px;
      padding-top: 1.6em;
  }

  .welcome__button:hover {
      width: 210px;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .welcome__button {
      width: 95%;
      height: 75px;
      font-size: 18px;
      margin-top: 1em;
      padding-top: 1.4em;
  }

  .welcome__button:hover {
      width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .welcome__button {
      width: 95%;
      height: 75px;
      font-size: 16px;
      margin-top: 1em;
      padding-top: 1.7em;
  }

  .welcome__button:hover {
      width: 100%;
  }
}

@media screen and (min-width: 991px) {
  .welcome__axs {
      font-size: 46px;
  }
  .welcome__details {
      font-size: 18px;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .welcome__axs {
      font-size: 30px;
  }
  .welcome__details {
      font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .welcome__slide {
    height: 600px;
  }

  .welcome__axs {
      font-size: 30px;
  }
  .welcome__details {
      font-size: 16px;
  }
}


/* For Providers Section */
.learn__more { 
  border: #DF3400 !important;
  padding: 10px !important;
  background-color: #DF3400 !important
}

.solution__list > li::marker{
  color: RGB(0, 171, 223) !important;
  font-size: 1.8em;
  margin-top: 10em;
}

.for__providers {
    overflow: hidden;
    background-size: 250%;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
    transition-property: all;
    transition-duration: .5s;
}

.for__providers--title {
    color: #ABABAB;
    font-size: 16px;
    font-weight: 700;
    padding-top: 80px;
    font-family: Roboto;
    letter-spacing: .2rem;
}

.for__providers--bg-blue {
  background: RGBA(11, 136, 238, 0.05) !important
}

.for__providers--details {
  filter: grayscale(1);
  opacity: 0;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: ease-in
}

.for__providers--details-animate {
  filter: grayscale(0);
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .learn__more { 
    width: 100%;
    padding: 15px !important;
  }
}


/* For Patients Section */
.for__patient {
  height: 910px !important;
  overflow: hidden;
  background-image: url("./_assets/Home/circles@3x.png");
  background-size: 250%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  transition-property: all;
  transition-duration: .5s;
}

.for__patient--animate {
  background-size: 100%;
}

.for__patient--title {
  color: #ABABAB;
  font-size: 16px;
  font-weight: 700;
  padding-top: 80px;
  font-family: Roboto;
  letter-spacing: .2rem;
}

.for__patient--secure {
  color: RGB(11, 136, 238);
}

.for__patient--secured {
  color: #454545;
  font-size: 35px;
  font-weight: 400;
  padding-top: 10px;
  font-family: Roboto;
  padding-bottom: 60px;
}

.for__patient--dashboard {
  width: 95%;
  height: 500px;
  background-size: 95%;
  background-image: url("./_assets/Home/macbook@3x.png");
  background-repeat: no-repeat;
  background-position: 3em 3em;
  transition-property: all;
  transition-duration: .8s;
}

.for__patient__iphone {
  width: 32%;
  z-index: 3 !important;
  position: absolute;
  margin-top: 20px;
  margin-left: 345px;
}

.for__patient__iphone--background2 {
  width: 31.5%;
  z-index: 1 !important;
  opacity: .4;
  position: relative;
  margin-top: 17%;
  margin-left: 21.7em;
}

.for__patient__iphone--background3 {
  width: 30%;
  opacity: .5;
  z-index: -4 !important;
  margin-left: 21.5em;
  margin-top: -50.5%;
}

.for__patient__dot--container {
  top: -6em;
  height: 250px;
  opacity: 0;
  position: relative;
  transition: opacity .8s, transform .8s;
}

.for__patient__dot--animate {
  opacity: 1;
  transform: translateX(0em) !important;
}

.for__patient__dot--title {
  color: #454545;
  font-size: calc(12px + 0.5vw);
  font-family: Roboto;
  padding-top: .5em;
}

.for__patient__dot--detail {
  color: #7E7E7E;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto;
  padding-top: 1em;
}

.for__patient--padding {
  margin-top: calc( 20em - 8vw);
}

.for__patient__dot {
  width: calc(45px + 2vw);
  height: calc(45px + 2vw);
  min-width: 70px;
  min-height: 70px;
  max-width: 85px; 
  max-height: 85px;
  display: inline-block;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.103);
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
}

.for__patient__dot--right {
  text-align: right;
}

.for__patient__dot--left {
  text-align: left;
}

.for__patient__dot--simple {
  background-size: 25%;
  background-image: url("./_assets/Home/simple@3x.png");
}
.for__patient__dot--message {
  background-size: 35%;
  background-image: url("./_assets/Home/message@3x.png");
}
.for__patient__dot--secured {
  background-size: 35%;
  background-image: url("./_assets/Home/secure@3x.png");
}
.for__patient__dot--platform {
  background-size: 35%;
  background-image: url("./_assets/Home/platform@3x.png");
}

@media only screen and (min-width: 1200px) {
  .for__patient__dot--title {
     font-size: 21px;
  }
}

@media only screen and (max-width: 991px) {
  .for__patient {
      height: 100% !important;
      background-image: none;
  }

  .for__patient--secured {
      padding-bottom: 170px;
  }

  .for__patient--padding {
      margin-top: 0em;
  }

  .for__patient__dot--title {
      font-size: 25px;
   }

  .for__patient__dot--detail {
      text-align: left !important;
      padding-left: 2em;
  }

  .for__patient__dot--container {
      width: 19em;
      height: auto;
      margin: auto;
      text-align: center !important;
      margin-bottom: 6em;
  }

  .for__patient__dot {
      width: 100px;
      height: 100px;
      max-width: 100px; 
      max-height: 100px;
  }

  .for__patient__dot--simple,
  .for__patient__dot--message,
  .for__patient__dot--secured, 
  .for__patient__dot--platform {
      background-size: 32%;
  }
}


/* Solution Section */
.solution {
  overflow: hidden;
  margin-bottom: 5vw;
  background-size: calc(485px + 80vw);
  background-image: url("./_assets/Solutions/map@3x.png");
  background-repeat: no-repeat;
  background-position: calc(-1 * (650px + 30vw)) -20vw;
}

.solution--reversed {
  overflow: hidden;
  margin-bottom: 5vw;
  background-size: calc(500px + 80vw);
  background-image: url("./_assets/Solutions/map-reversed@3x.png");
  background-repeat: no-repeat;
  background-position: calc((100px + 60vw)) -4vw;
}

.solution--header {
  color: RGB(69, 69, 69);
  font-size: 56px;
  font-family: Roboto;
  line-height: 56px;
  background-image: linear-gradient(180deg, RGB(255, 255, 255) 0%, RGB(247, 251, 254) 100%);
}

.solution__title {
  color: RGB(69, 69, 69);
  font-size: calc(15px + 1.5vw);
  margin-top: 70px;
  font-family: Roboto;
  line-height: 56px;
  margin-bottom: 20px;
}

.solution__info:hover {
  cursor: pointer;
}

.solution__info--step {
  color: RGB(11, 136, 238);
  font-size: calc(15px + 1vw);
  font-weight: 200 !important;
  line-height: 31px;
}

.solution__info--title {
  color: RGB(69, 69, 69);
  font-size: calc(10px + .5vw);
  font-weight: 700;
  line-height: 31px;
  font-family: Roboto;
}

.solution__info--description {
  color: RGB(126, 126, 126);
  font-size: calc(6px + .5vw);
  line-height: 28px;
  font-family: Roboto !important;
}

.solution--image {
  opacity: 0;
  transform: translateX(10em);
  margin-top: 9em;
  transition: opacity .8s, transform .5s;
}

.solution--image-animate {
  opacity: 1 !important;
  transform: translateX(0em) !important;
}

.reversed.solution--image {
  transform: translateX(-10em);
}

.reversed.solution--image {
  left: unset;
  right: 1em !important;
}

@media screen and (min-width: 991px) {
  .solution__title {
      font-size: 35px;
  }
  .solution__info--step {
      font-size: 33px;
  }
  
  .solution__info--title {
      font-size: 20px;
  }
  
  .solution__info--description {
      font-size: 16px;
  }
}

@media screen and (min-width: 575px) and (max-width: 991px) {
  .solution--image {
      opacity: 0;
      transform: translateX(10em);
      margin-top: 3em;
      transition: opacity .8s, transform .5s;
  }

  .reversed.solution--image {
      left: unset;
      right: 0em !important;
  }

  .solution {
      overflow: hidden;
      background-image: none;
  }
  
  .solution--reversed {
      overflow: hidden;
      background-image: none;
  }

  .solution__title {
      font-size: 35px;
      margin-top: 1.5em;
  }
  .solution__info--step {
      font-size: 33px;
  }
  
  .solution__info--title {
      font-size: 20px;
      margin-left: .5em;
  }
  
  .solution__info--description {
      font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .solution--image {
      left: 0em;
      transform: translateX(10em);
      margin-top: 3em;
      transition: opacity .8s, transform .5s;
  }

  .reversed.solution--image {
      left: unset;
      right: 0em !important;
  }

  .solution {
      overflow: hidden;
      padding-left: 2.5em;
      padding-right: 2.5em;
      background-image: none;
  }
  
  .solution--reversed {
      overflow: hidden;
      padding-left: 2.5em;
      padding-right: 2.5em;
      background-image: none;
  }

  .solution__title {
      font-size: 28px;
  }
  .solution__info--step {
      font-size: 25px;
  }
  
  .solution__info--title {
      font-size: 16px;
      margin-left: 0em;
  }
  
  .solution__info--description {
      font-size: 16px;
  }
}


/* About Section */
.about__axs {
  height: 525px;
  overflow: hidden;
  box-shadow: 0px 10px 30px RGBA(0, 0, 0, 0.02);
  padding-top: 7em;
  padding-left: 1em;
}

.about__axs--title {
  color: #454545;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-family: Roboto;
  margin-bottom: 1em;
  letter-spacing: .2rem;
}

.about__axs--detail {
  color: #7E7E7E;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto;
  margin-bottom: 3em;
}

.about__axs--email {
  color: #0B88EE;
  font-size: 40px;
  line-height: 72px;
  font-family: Roboto;
  margin-bottom: 1em;
}

.about__dot--container {
  width: 14em;
  height: 19em;
  position: relative;
}

.about__dot--small {
  top: 5em;
  left: 4em;
  width: 91px;
  height: 91px;
  border: 5px solid #DF3400;
  opacity: .5;
  position: relative;
  min-width: 70px;
  max-width: 91px; 
  max-height: 91px;
  min-height: 70px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.103);
  border-radius: 50%;
}

.about__dot {
  top: 7em;
  left: 2em;
  width: 253px;
  height: 253px;
  z-index: -1;
  opacity: .7 !important;
  position: absolute;
  min-width: 70px;
  max-width: 253px; 
  min-height: 70px;
  max-height: 253px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.103);
  border-radius: 50%;
  background: linear-gradient(150deg, rgba(255,255,255,1) 10%,  rgb(163, 208, 221) 30%, rgb(108, 195, 221) 40%, rgba(0, 171, 223) 60%) !important;
}

.meet__the__team {
  height: 950px;
  text-align: right;
  padding-top: 7em;
  margin-left: -15em;
}

.meet__the__team--subtitle {
  color: #ABABAB;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  font-family: Roboto;
  margin-bottom: 1em;
  letter-spacing: .2rem;
}

.meet__the__team--title {
  color: #454545;
  font-size: 56px;
  line-height: 72px;
  font-family: Roboto;
  margin-bottom: 1em;
}

.meet__the__team--highlight {
  color: #0B88EE;
}

.meet__the__team__dot--container {
  width: 450px;
  height: 650px;
  position: relative;
}

.meet__the__team__dot--one {
  top: 25em;
  right: 6em;
  width: 520px;
  height: 520px;
  opacity: .9 !important;
  position: absolute;
  min-width: 70px;
  max-width: 585px; 
  min-height: 70px;
  max-height: 585px;
  border-radius: 50%;
  background: linear-gradient(150deg, rgba(163,0,223,1) 20%, rgba(255,255,255,1) 85%) !important;
}

.meet__the__team__dot--two {
  top: 25em;
  right: 2em;
  width: 520px;
  height: 520px;
  opacity: 1 !important;
  position: absolute;
  min-width: 70px;
  max-width: 585px; 
  min-height: 70px;
  max-height: 585px;
  border-radius: 50%;
  background: linear-gradient(150deg, rgba(60, 223, 0, 1) 20%, rgba(255,255,255,1) 80%) !important;
}

@media (max-width: 1400px) {
  .meet__the__team {
      padding-top: 7em;
  }
}

@media (max-width: 991px) {
  .about__axs--email {
      font-size: 25px;
  }
  .meet__the__team {
      height: 900px;
      overflow: hidden;
      position: relative;
      text-align: left;
      margin-left: 0em;
      padding-top: 2em;
  }

  .meet__the__team--team-slider {
      top: 6em;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .meet__the__team__dot--container {
      width: unset;
      height: unset;
      z-index: -1 !important;
  }
  
  .meet__the__team__dot--one {
      top: -35em;
      left: -10em;
      width: 520px;
      height: 520px;
  }
  
  .meet__the__team__dot--two {
      top: -35em;
      left: -6em;
      width: 520px;
      height: 520px;
  }
  
  .about__dot--small {
      top: 12em;
      left: 7em;
      right: 0;
  }
  
  .about__dot {
      top: 14em;
      left: 5em;
      right: 0;
  }
}

@media (max-width: 767px) {
  .about__axs--email {
      font-size: 25px;
      line-height: 0px;
      font-family: Roboto;
      margin-bottom: 5em;
  }
  
  .meet__the__team {
      height: 900px;
      overflow: hidden;
      position: relative;
      text-align: left; 
      margin-left: 0em;
      padding-top: 2em;
  }

  .meet__the__team--team-slider {
      top: 6em;
      left: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .meet__the__team__dot--one {
      top: -35em;
      left: -10em;
      width: 520px;
      height: 520px;
  }
  
  .meet__the__team__dot--two {
      top: -35em;
      left: -6em;
      width: 520px;
      height: 520px;
  }
  
  .about__dot--small {
      top: 12em;
      left: 7rem;
      right: 0;
  }
  
  .about__dot {
      top: 14em;
      left: 5rem;
      right: 0;
  }
}

@media (max-width: 575px) {
  .meet__the__team {
      height: 900px;
      overflow: hidden;
      position: relative;
      text-align: left; 
      margin-left: 0em;
      padding-top: 2em;
  }

  .meet__the__team--title {
      font-size: 10vw;
  }

  .meet__the__team--team-slider {
      top: 6em;
      left: calc(16vw * 2);
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
  }

  .meet__the__team__dot--one {
      top: -35em;
      left: -10em;
      width: 520px;
      height: 520px;
  }
  
  .meet__the__team__dot--two {
      top: -35em;
      left: -6em;
      width: 520px;
      height: 520px;
  }

  .about__axs {
      margin-bottom: 4em;
  }

  .about__axs--email {
      font-size: 25px;
      line-height: 0px;
      font-family: Roboto;
      margin-bottom: 5em;
  }

  .about__dot--small {
      top: 45px;
      left: 1em;
      z-index: 99;
      width: 55px;
      height: 55px;
      min-width: 20px;
      min-height: 20px;
      position: absolute;
  }
  
  .about__dot {
      top: 65px;
      left: 0em;
      width: 150px;
      height: 150px;
      position: absolute;
      margin-bottom: 1em;
  }

  .about__dot--container {
      top: -8em;
      right: 0;
      width: 25%;
      height: 19em;
      position: absolute;
      margin-left: auto;
  }
}