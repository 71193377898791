@font-face {
    font-family: "Roboto";
    src: url("../../_assets/fonts/Roboto/Roboto-Regular.ttf");
}

.team__slider--container {
  width: 60%;
  opacity: 0;
  transition-property: all;
  transition-duration: 1s;
}

.team__slider--show {
  opacity: 1 !important
}

.team__member,
.team__member--selected,
.team__member--end-hidden,
.team__member--start-hidden,
.team__member--next,
.team__member--next-next {
    top: -5em;
    left: -10vw;
    position: absolute !important;
    transition-property: all;
    transition-duration: .5s;
}

.team__title {
    color: RGB(69, 69, 69);
    font-size: 24px;
    font-weight: 700;
    font-family: Roboto;
}

.team__subtitle {
    color: RGB(126, 126, 126);
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto;
}

.team__member--image {
    max-width: 15em;
    box-shadow: 15px 15px 30px RGBA(0, 0, 0, 0.13);
    transform: translateY(6em);
    transition-property: all;
    transition-duration: .5s;
}

.team__member--selected > 
.team__member--image {
    transform: translateY(0em);
}

.team__member--hidden-end {
    opacity: 0;
    transform: translateX(-21.2em);
}

.team__member--hidden-start {
    opacity: 0;
    transform: translateX(61.2em);
}

.team__member--first {
    transform: translateX(41.2em);
}

.team__member--second {
    transform: translateX(21.2em);
}


