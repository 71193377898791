@font-face {
    font-family: "Roboto";
    src: url("../../_assets/fonts/Roboto/Roboto-Regular.ttf");
}

.axs__navbar {
    height: 120px;
}

.axs__navbar--logo {
    height: 45px;
}

.dropdown-menu  {
    left: unset !important;
    right: 0 !important;
    border: 1px solid RGB(55, 145, 245) !important;
}

.use__cases--dropdown-title {
    color: RGB(204, 204, 204) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: Roboto !important;
    letter-spacing: .2rem;
}

.use__cases--dropdown-item {
    color: RGB(69, 69, 69, .9) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Roboto !important;
}

.use__cases--dropdown-item:hover {
    color: rgba(0,0,0) !important;
    background-color: white !important;
}

@media only screen and (max-width: 767px) {
    .axs__navbar--logo {
        height: calc(5px + 5vw);
        margin-top: 1vw;
    }
}

@media only screen and (max-width: 575px) {
    .axs__navbar {
        display: none !important;
    }
}