@font-face {
    font-family: "Roboto";
    src: url("../../_assets/fonts/Roboto/Roboto-Regular.ttf");
}

.axs__footer {
    height: 120px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.axs__footer--logo {
    height: 45px;
}

.axs__footer__details {
    color: #7E7E7E;
    font-size: 16px;
    font-family: Roboto;
    line-height: 32px;
}

.axs__footer__contact,
.axs__footer__clients,
.axs__footer__download {
    color: #CCCCCC !important;
    font-size: 14px;
    font-weight: 700;
    font-family: Roboto;
    letter-spacing: .2rem;
}

.axs__footer__email,
.axs__footer__clients__list,
.axs__footer__contact__address {
    color: #7E7E7E !important;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    padding-top: 1em;
    letter-spacing: 0rem;
    line-height: 34px;
}

.axs__footer__client { 
    color: #7E7E7E !important;
}

.axs__footer__clients__list{
    line-height: 36px;
}

.axs__footer__contact__address {
    line-height: 28px;
}

.axs__footer__copy {
    color: #7E7E7E;
    padding: 2em;
    font-size: 14px;
    margin-top: 7em;
    line-height: 24px;
    font-family: Roboto;
}

@media only screen and (min-width: 575px) and (max-width: 991px) {
    .axs__footer--logo {
        height: 2em;
        margin-top: 4em;
    }

    .axs__footer__copy {
        margin-top: 0em;
    }
}

@media only screen and (max-width: 575px) {
    .axs__footer {
        height: 100%;
        padding-top: 0px;
        padding-bottom: 40px;
    }

    .axs__footer--logo {
        height: 2em;
        margin-top: 0em;
        margin-bottom: 1.5em;
    }

    .axs__footer__copy {
        margin-top: 0em;
    }

    .axs__footer--info {
        margin: auto !important;
        width: 15em;
    }

    .axs__footer__contact,
    .axs__footer__clients__list {
        margin-bottom: 2em;
    }

    .axs__footer__copy {
        font-size: 11px;
        text-align: center;
    }
}